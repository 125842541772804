<template>
  <vx-card :title="(this.id ? 'Edit' : 'Create') + ' Missing Stock Settlement'">
    <div class="vx-row mb-6">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
      >Back
      </vs-button>
    </div>

    <div class="flex md:flex-row">
      <div class="w-full pr-6 md:basis-1/2">
        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>MSS ID</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="code" disabled readonly></vs-input>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Operating Unit</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <OperatingUnit
              v-model="selectedOperatingUnit"
              @selected="(val) => { this.selectedOperatingUnit = val }"
            ></OperatingUnit>
            <span class="text-danger text-sm" v-show="errors.has('Cost Center')">{{
                errors.first("Cost Center")
              }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Warehouse</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <Warehouse
              :value="this.selectedWarehouse"
              @selected="(val) => { this.selectedWarehouse = val }"
              :warehouseID="this.selectedOperatingUnit ? this.selectedOperatingUnit.warehouse_id : null"
            ></Warehouse>
            <span class="text-danger text-sm" v-show="errors.has('Warehouse')">{{ errors.first("Warehouse") }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Warehouse Area</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <WarehouseArea
              v-model="selectedWarehouseArea"
              :warehouseID="selectedWarehouse ? selectedWarehouse.id : null"
              @selected="handleWarehouseWarehouseAreaSelected"
            />
            <span class="text-danger text-sm" v-show="errors.has('Warehouse Area')">{{
                errors.first("Warehouse Area")
              }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Document Date</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <Date
              :default="false"
              v-model="documentDate"
              :max-date="new Date(Date.now())"
              @selected="(val) => { this.documentDate = val }"
            ></Date>
            <span class="text-danger text-sm" v-show="errors.has('Document Date')">{{
                errors.first("Document Date")
              }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>COA</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <COA
              v-model="selectedCOA"
              @selected="(val) => { this.selectedCOA = val }"
            ></COA>
            <span class="text-danger text-sm" v-show="errors.has('COA')">{{
                errors.first("COA")
              }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Cost Center</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <CostCenter
              v-model="selectedCostCenter"
              @selected="(val) => { this.selectedCostCenter = val }"
            ></CostCenter>
            <span class="text-danger text-sm" v-show="errors.has('Cost Center')">{{
                errors.first("Cost Center")
              }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Reason</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <Reason
              v-model="selectedReason"
              @selected="(val) => { this.selectedReason = val }"
            ></Reason>
            <span class="text-danger text-sm" v-show="errors.has('Reason')">{{
                errors.first("Reason")
              }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>External Code</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input v-model="external_code" class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('External Code')">{{
                errors.first("External Code")
              }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Note</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-textarea v-model="notes" class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('Notes')">{{ errors.first("Notes") }}</span>
          </div>
        </div>
      </div>

      <div class="w-full md:basis-1/2">
        <Attachment
          v-model="fileAttachment"
        ></Attachment>
        <span class="text-danger text-sm" v-show="errors.has('fileAttachment')">{{
            errors.first("fileAttachment")
          }}</span>
      </div>
    </div>

    <div class="vx-col sm:w-5/5 w-full mb-2" v-for="(line, indextr) in itemLine" :key="indextr">
      <div class="vx-col sm:w-5/5 w-full mb-2">
        <div class="vx-row mb-2">
          <div class="vx-col w-4/12">
            <label class="vs-input--label">SKU</label>
          </div>
          <div class="vx-col w-4/12">
            <label class="vs-input--label">Batch External / ED</label>
          </div>
          <div class="vx-col w-1/12">
            <label class="vs-input--label">Unit</label>
          </div>
          <div class="vx-col w-2/12">
            <label class="vs-input--label">System Qty</label>
          </div>
          <div class="vx-col w-1/12">
            <label class="vs-input--label">+ SKU</label>
          </div>
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col w-4/12">
          <multiselect
            :allowEmpty="false"
            :options="line.option_sku"
            v-model="line.selected_sku"
            :select-label="''"
            :deselect-label="''"
            :label="'label'"
            :track-by="'sku_code'"
            :placeholder="'Select SKU'"
            @input="fetchBatch(line.selected_sku.sku_code, line)"
          />
        </div>

        <div class="vx-col w-4/12">
          <multiselect
            :allowEmpty="false"
            :options="line.option_batch"
            v-model="line.selected_batch"
            :select-label="''"
            :deselect-label="''"
            :label="'label'"
            :track-by="'batch'"
            :placeholder="'Batch'"
            @input="fetchUnit(line.selected_sku.sku_code, line.selected_batch.batch_external, line)"
          />
        </div>

        <div class="vx-col w-1/12">
          <multiselect
            :allowEmpty="false"
            :options="line.option_hu"
            v-model="line.selected_hu"
            :select-label="''"
            :deselect-label="''"
            :label="'label'"
            :track-by="'amount'"
            :placeholder="'Unit'"
          />
        </div>

        <div class="vx-col w-2/12">
          <vs-input
            type="number"
            :value="line.selected_hu ? line.selected_hu.amount : ''"
            class="w-full bg-grey-light"
            disabled
            readonly
          ></vs-input>
        </div>

        <div class="vx-col w-1/12">
          <div class="vx-input-group flex">
            <vs-button
              v-if="indextr === 0"
              size="small" color="success"
              icon-pack="feather" icon="icon-plus"
              title="Add SKU"
              @click="addLine"
            />
            <vs-button
              v-else
              size="small" color="danger"
              icon-pack="feather" icon="icon-trash"
              title="Delete SKU"
              @click="removeLine(indextr)"
            />
          </div>
        </div>
      </div>

      <div class="vx-col sm:w-5/5 w-full mb-2" v-for="(charge, chargeIndex) in line.charge" :key="chargeIndex">
        <div class="vx-row mb-2">
          <div class="vx-col w-1/12">
            <label class="vs-input--label">Settlement Qty</label>
          </div>
          <div class="vx-col w-2/12">
            <label class="vs-input--label">Settlement Value</label>
          </div>
          <div class="vx-col w-2/12">
            <label class="vs-input--label">Charge To</label>
          </div>
          <div class="vx-col w-1/12">
            <label class="vs-input--label">+ Charge To</label>
          </div>
        </div>

        <div class="vx-row mb-2">
          <div class="vx-col w-1/12">
            <vs-input
              type="number"
              v-model="charge.settlement_qty"
              class="w-full"
            ></vs-input>
          </div>

          <div class="vx-col w-2/12">
            <vs-input
              v-model="charge.settlement_value"
              class="w-full bg-grey-light"
              disabled
              readonly
            ></vs-input>
          </div>

          <div class="vx-col w-2/12">
            <vs-input
              v-model="charge.charge_to"
              class="w-full"
            ></vs-input>
          </div>

          <div class="vx-col w-1/12">
            <div class="vx-input-group flex">
              <vs-button
                v-if="chargeIndex === 0"
                @click="addChargeRow(indextr)"
                size="small"
                color="success"
                icon-pack="feather"
                icon="icon-plus"
                title="Add Row"
              />
              <vs-button
                v-else
                size="small" color="danger"
                icon-pack="feather" icon="icon-trash"
                title="Delete Charge"
                @click="removeChargeRow(indextr, chargeIndex)"
              />
            </div>
          </div>
        </div>
      </div>

      <vs-divider></vs-divider>
    </div>

    <br>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/1 w-full">
        <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
          <vs-button class="mr-3 mb-2" @click="handleSave">Save</vs-button>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import Warehouse from "./component/WarehouseV2.vue";
import WarehouseArea from "./component/WarehouseArea.vue";
import Date from "./component/Date.vue";
import CostCenter from "./component/CostCenter.vue"
import COA from "./component/COA.vue"
import Reason from "./component/Reason.vue"
import Attachment from "./component/Attachment.vue";
import moment from "moment";
import OperatingUnit from "./component/OperatingUnit.vue";

export default {
  components: {
    OperatingUnit,
    Attachment,
    Date,
    Warehouse,
    WarehouseArea,
    CostCenter,
    COA,
    Reason,
  },
  data() {
    return {
      id: this.$route.query.id ? parseInt(this.$route.query.id) : null,
      code: "Auto Generated",
      selectedOperatingUnit: null,
      selectedWarehouse: null,
      selectedWarehouseArea: null,
      documentDate: null,
      selectedCOA: null,
      selectedCostCenter: null,
      selectedReason: null,
      external_code: null,
      notes: null,
      fileAttachment: [],
      itemLine: [
        {
          option_sku: [],
          selected_sku: null,
          option_batch: [],
          selected_batch: null,
          option_hu: [],
          selected_hu: null,
          system_qty: null,
          charge: [
            {
              settlement_qty: null,
              settlement_value: "TO BE CALCULATE",
              charge_to: null,
            }
          ]
        }
      ]
    };
  },
  methods: {
    handleBack() {
      this.$vs.loading();
      this.$router.push({
        name: "missing-stock-settlement",
        query: {tab: this.$route.query.tab},
      });
      this.$vs.loading.close();
    },
    handleWarehouseWarehouseAreaSelected(val) {
      this.fetchItems(this.selectedWarehouse.id, val.id);
      this.selectedWarehouseArea = val;
    },
    addChargeRow(lineIndex) {
      this.itemLine[lineIndex].charge.push({
        settlement_qty: null,
        settlement_value: "TO BE CALCULATE",
        charge_to: null,
      });
    },
    removeChargeRow(lineIndex, chargeIndex) {
      this.itemLine[lineIndex].charge.splice(chargeIndex, 1);
    },
    removeLine(index) {
      this.itemLine.splice(index, 1);
    },
    addLine() {
      this.itemLine.push({
        option_sku: [],
        selected_sku: null,
        option_batch: [],
        selected_batch: null,
        option_hu: [],
        selected_hu: null,
        system_qty: null,
        charge: [
          {
            settlement_qty: null,
            settlement_value: "TO BE CALCULATE",
            charge_to: null,
          }
        ]
      });
      this.fetchItems(this.selectedWarehouse.id, this.selectedWarehouseArea.id);
    },
    handleSave() {
      this.errors.clear();
      this.$validator.validateAll().then((result) => {

        const hasValidLineItem = this.itemLine.every(item =>
          item.selected_sku && item.selected_batch && item.selected_hu && item.charge.every(charge => charge.settlement_qty && charge.charge_to)
        );

        if (!hasValidLineItem) {
          this.$vs.notify({
            title: "Error",
            text: "Each line item must have all required fields filled",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          result = false;
        }

        if (!this.selectedOperatingUnit) {
          this.errors.add({
            field: "Operating Unit",
            msg: "Operating Unit is required",
          });
          result = false;
        }

        if (!this.selectedWarehouse) {
          this.errors.add({
            field: "Warehouse",
            msg: "Warehouse is required",
          });
          result = false;
        }

        if (!this.external_code) {
          this.errors.add({
            field: "External Code",
            msg: "External Code is required",
          });
          result = false;
        }

        if (!this.selectedWarehouseArea) {
          this.errors.add({
            field: "Warehouse Area",
            msg: "Warehouse Area is required",
          });
          result = false;
        }

        if (!this.selectedCOA) {
          this.errors.add({
            field: "COA",
            msg: "COA is required",
          });
          result = false;
        }

        if (!this.selectedReason) {
          this.errors.add({
            field: "Reason",
            msg: "Reason is required",
          });
          result = false;
        }

        if (!this.selectedCostCenter) {
          this.errors.add({
            field: "Cost Center",
            msg: "Cost Center is required",
          });
          result = false;
        }

        if (!this.documentDate) {
          this.errors.add({
            field: "Document Date",
            msg: "Document Date Required"
          });
          result = false;
        }
        if (!this.notes) {
          this.errors.add({
            field: "Notes",
            msg: "Note Required"
          });
          result = false;
        }
        if (this.fileAttachment.length === 0) {
          this.errors.add({
            field: "fileAttachment",
            msg: "This Attachment is required",
          });
          result = false;
        }

        const batchSet = new Set();
        this.itemLine.forEach(item => {
          let errorMessages = [];
          if (item.selected_sku && item.selected_batch && item.selected_hu) {
            const batchKey = `${item.selected_sku.sku_code}-${item.selected_batch.batch_external}-${item.selected_hu.unit}`;
            if (batchSet.has(batchKey)) {
              errorMessages.push("Duplicate SKU, batch, and unit are not allowed");
              result = false;
            } else {
              batchSet.add(batchKey);
            }
          }

          if (errorMessages.length > 0) {
            this.$vs.notify({
              title: "Error",
              text: errorMessages[0],
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            return;
          }
        });

        this.itemLine.forEach((line, index) => {
          if (!this.validateSettlementQty(line)) {
            this.$vs.notify({
              title: "Error",
              text: `Settlement Qty for ${line.selected_sku.label} exceeds System Qty`,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            result = false;
          }
        });

        if (result) {
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    validateSettlementQty(line) {
      const systemQty = line.selected_hu ? Number(line.selected_hu.amount) : 0;
      const totalSettlementQty = line.charge.reduce((sum, charge) => sum + Number(charge.settlement_qty || 0), 0);
      return totalSettlementQty <= systemQty;
    },
    fetchItems(warehouseID, warehouseAreaID) {
      const baseUrl = `/api/wms/v1/master/item-by-warehouse-warehouse-area`;
      this.$http
        .get(baseUrl, {
          params: {
            order: "item_name",
            sort: "asc",
            warehouse_id: warehouseID,
            warehouse_area_id: warehouseAreaID,
          }
        })
        .then(resp => {
          if (resp.code === 200) {
            resp.data.records.map(function (x) {
              x.label = x.sku_code + " - " + x.item_name;
              return x;
            });

            this.itemLine.forEach(line => {
              line.option_sku = resp.data.records;
            });
          } else {
            this.itemLine.forEach(line => {
              line.option_sku = [];
            });
          }
        });
    },
    fetchBatch(skuCode, line) {
      const baseUrl = `/api/wms/v1/master/batch-distinct`;
      this.$http
        .get(baseUrl, {
          params: {
            order: "batch_external",
            sort: "asc",
            warehouse_id: this.selectedWarehouse.id,
            sku_code: skuCode,
            warehouse_area_id: this.selectedWarehouseArea.id,
          }
        })
        .then(resp => {
          if (resp.code === 200) {
            const batches = resp.data.records
              .filter(x => x.batch_external !== null && x.batch_external !== "")
              .map(x => {
                return {
                  label: x.batch_external + " / " + moment(x.expired_date).format('YYYY-MM-DD'),
                  batch: x.batch,
                  batch_external: x.batch_external,
                  expired_date: moment(x.expired_date).format('YYYY-MM-DD'),
                };
              });

            line.option_batch = batches;
          } else {
            line.option_batch = [];
          }
        });
    },
    fetchUnit(skuCode, batchExternal, line) {
      const baseUrl = `/api/wms/v1/master/unit-hu-by-item`;
      this.$http
        .get(baseUrl, {
          params: {
            order: "unit",
            sort: "asc",
            warehouse_id: this.selectedWarehouse.id,
            sku_code: skuCode,
            warehouse_area_id: this.selectedWarehouseArea.id,
            batch_external: batchExternal,
          }
        })
        .then(resp => {
          if (resp.code === 200) {
            const units = resp.data.records.map(x => ({
              unit: x.unit,
              amount: x.amount,
              amount_uom: x.amount_uom,
              label: x.unit
            }));
            line.option_hu = units;
          } else {
            line.option_hu = [];
          }
        });
    },
    putData() {
      let form = new FormData();
      form.append("operating_unit_id", this.selectedOperatingUnit.id);
      form.append("warehouse_id", this.selectedWarehouse.id);
      form.append("warehouse_area_id", this.selectedWarehouseArea.id);
      form.append("document_date", moment(this.documentDate).format("YYYY-MM-DD"));
      form.append("coa_id", this.selectedCOA.id);
      form.append("cost_center_id", this.selectedCostCenter.id);
      form.append("reason_id", this.selectedReason.id);
      form.append("external_code", this.external_code);
      form.append("note", this.notes);

      // Attachment
      var attachment_id;
      for (let index = 0; index < this.fileAttachment.length; index++) {
        if (typeof this.fileAttachment[index].id === "undefined") {
          attachment_id = 0;
        } else {
          attachment_id = this.fileAttachment[index].id;
        }
        form.append("attachment_id[]", attachment_id);
        form.append("attachments[]", this.fileAttachment[index].File);
      }

      // Prepare the itemLines array structure
      let itemLines = this.itemLine.map((item) => {
        return {
          sku_code: item.selected_sku ? item.selected_sku.sku_code : null,
          batch_external: item.selected_batch ? item.selected_batch.batch_external : null,
          batch: item.selected_batch ? item.selected_batch.batch : null,
          expired_date: item.selected_batch ? item.selected_batch.expired_date : null,
          hu: item.selected_hu ? item.selected_hu.unit : null,
          system_qty: item.selected_hu ? item.selected_hu.amount : null,
          amount_uom: item.selected_hu ? item.selected_hu.amount_uom : null,
          charge: item.charge.map(chargeItem => ({
            settlement_qty: chargeItem.settlement_qty.toString(),
            settlement_value: chargeItem.settlement_value,
            charge_to: chargeItem.charge_to,
          }))
        };
      });

      // Append the itemLines array as JSON
      form.append("item_line", JSON.stringify(itemLines));

      // do post
      this.$vs.loading();
      this.$http.put(`/api/wms/v1/missing-stock-settlements/${this.id}`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((resp) => {
        if (resp.code === 200) {
          this.$vs.notify({
            title: "Success",
            text: "Missing Stock Settlement has been Updated",
            color: "success",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          this.handleBack();
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
        this.$vs.loading.close();
      });
    },
    postData() {
      let form = new FormData();
      form.append("operating_unit_id", this.selectedOperatingUnit.id);
      form.append("warehouse_id", this.selectedWarehouse.id);
      form.append("warehouse_area_id", this.selectedWarehouseArea.id);
      form.append("document_date", moment(this.documentDate).format("YYYY-MM-DD"));
      form.append("coa_id", this.selectedCOA.id);
      form.append("cost_center_id", this.selectedCostCenter.id);
      form.append("reason_id", this.selectedReason.id);
      form.append("external_code", this.external_code);
      form.append("note", this.notes);

      // Attachment
      for (let index = 0; index < this.fileAttachment.length; index++) {
        form.append("attachments[]", this.fileAttachment[index].File);
      }

      // Prepare the itemLines array structure
      let itemLines = this.itemLine.map((item) => {
        return {
          sku_code: item.selected_sku ? item.selected_sku.sku_code : null,
          batch: item.selected_batch ? item.selected_batch.batch : null,
          batch_external: item.selected_batch ? item.selected_batch.batch_external : null,
          expired_date: item.selected_batch ? item.selected_batch.expired_date : null,
          hu: item.selected_hu ? item.selected_hu.unit : null,
          system_qty: item.selected_hu ? item.selected_hu.amount : null,
          amount_uom: item.selected_hu ? item.selected_hu.amount_uom : null,
          charge: item.charge.map(chargeItem => ({
            settlement_qty: chargeItem.settlement_qty,
            settlement_value: chargeItem.settlement_value,
            charge_to: chargeItem.charge_to,
          }))
        };
      });

      // Append the itemLines array as JSON
      form.append("item_line", JSON.stringify(itemLines));

      // do post
      this.$vs.loading();
      this.$http.post("/api/wms/v1/missing-stock-settlement", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((resp) => {
        if (resp.code === 200) {
          this.$vs.notify({
            title: "Success",
            text: "Missing Stock Settlement has been saved",
            color: "success",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          this.handleBack();
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
        this.$vs.loading.close();
      });
    },
    getData() {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/missing-stock-settlement/" + this.id).then((resp) => {
        if (resp.code === 200) {
          const data = resp.data.mss;
          const lineData = resp.data.mss_line;
          this.code = data.Code;
          this.selectedOperatingUnit = {
            id: data.OperatingUnitID,
            code: data.OperatingUnitCode,
            cost_center_id: data.CostCenterID,
            name: data.OperatingUnitName,
            warehouse_id: data.WarehouseID,
            label: `${data.OperatingUnitCode} - ${data.OperatingUnitName}`
          };
          this.selectedWarehouse = {
            id: data.WarehouseID,
            code: data.WarehouseCode,
            name: data.WarehouseName,
            label: `${data.WarehouseCode} - ${data.WarehouseName}`
          };
          this.selectedWarehouseArea = {
            id: data.WarehouseAreaID,
            code: data.WarehouseAreaCode,
            name: data.WarehouseAreaName,
            label: `${data.WarehouseAreaCode} - ${data.WarehouseAreaName}`
          };
          this.documentDate = moment(data.DocumentDate).toDate();
          this.selectedCOA = {
            id: data.ChartOfAccountsID,
            code: data.ChartOfAccountsCode,
            name: data.ChartOfAccountsName,
            label: `${data.ChartOfAccountsCode} - ${data.ChartOfAccountsName}`
          };
          this.selectedCostCenter = {
            id: data.CostCenterID,
            code: data.CostCenterCode,
            name: data.CostCenterName,
            label: `${data.CostCenterCode} - ${data.CostCenterName}`
          };
          this.selectedReason = {
            id: data.ReasonsID,
            code: data.ReasonsCode,
            name: data.Reasons,
            label: `${data.ReasonsCode} - ${data.Reasons}`
          };
          this.external_code = data.ExternalCode;
          this.notes = data.Note;
          this.fileAttachment = resp.data.mss_files.map(file => ({
            id: file.id,
            FileUrl: file.FileUrl,
            NameFile: file.FileName
          }));

          this.itemLine = lineData.map(lines => ({
            option_sku: [],
            selected_sku: {
              sku_code: lines.SkuCode,
              label: `${lines.SkuCode} - ${lines.ItemName}`
            },
            option_batch: [],
            selected_batch: {
              batch: lines.Batch,
              batch_external: lines.BatchExternal,
              expired_date: moment(lines.ExpiredDate).format('YYYY-MM-DD'),
              label: `${lines.BatchExternal} / ${moment(lines.ExpiredDate).format('YYYY-MM-DD')}`
            },
            option_hu: [],
            selected_hu: {
              unit: lines.Unit,
              amount: lines.SystemQty,
              amount_uom: lines.AmountUOM,
              label: lines.Unit
            },
            system_qty: lines.SystemQty,
            charge: lines.charges.map(charge => ({
              settlement_qty: charge.SettlementQty,
              settlement_value: "TO BE CALCULATE",
              charge_to: charge.ChargeTo
            }))
          }));

          this.fetchItems(this.selectedWarehouse.id, this.selectedWarehouseArea.id);
          this.itemLine.forEach(line => {
            if (line.selected_sku && this.selectedWarehouseArea) {
              this.fetchBatch(line.selected_sku.sku_code, line);
              this.fetchUnit(line.selected_sku.sku_code, line.selected_batch.batch_external, line);
            }
          });

        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check"
          });
        }
        this.$vs.loading.close();
      });
    },
    resetItemLine() {
      this.itemLine = [
        {
          option_sku: [],
          selected_sku: null,
          option_batch: [],
          selected_batch: null,
          option_hu: [],
          selected_hu: null,
          system_qty: null,
          charge: [
            {
              settlement_qty: null,
              settlement_value: "TO BE CALCULATE",
              charge_to: null,
            }
          ]
        }
      ];
    },
  },
  mounted() {
    if (this.id) {
      this.getData();
    }
  },
  watch: {
    itemLine: {
      handler(newVal) {
        newVal.forEach(line => {
          this.$watch(() => line.selected_sku, (newSku, oldSku) => {
            if (newSku !== oldSku) {
              line.selected_batch = null;
              line.option_batch = [];
              line.option_hu = [];
              line.selected_hu = null;
              line.system_qty = null;
              line.charge = [
                {
                  settlement_qty: null,
                  settlement_value: "TO BE CALCULATE",
                  charge_to: null,
                }
              ];
            }
          });

          this.$watch(() => line.selected_batch, (newBatch, oldBatch) => {
            if (newBatch !== oldBatch) {
              line.option_hu = [];
              line.selected_hu = null;
              line.system_qty = null;
              line.charge = [
                {
                  settlement_qty: null,
                  settlement_value: "TO BE CALCULATE",
                  charge_to: null,
                }
              ];
            }
          });
        });
      },
      deep: true,
      immediate: true
    },
    selectedWarehouse: {
      handler(newVal, oldVal) {
        if (newVal.id !== oldVal.id) {
          this.resetItemLine();
        }
      },
      immediate: true
    },
    selectedWarehouseArea: {
      handler(newVal, oldVal) {
        if (oldVal !== null && newVal !== oldVal) {
          this.resetItemLine();
        }
      },
      immediate: true
    },
    selectedOperatingUnit: {
      handler(newVal, oldVal) {
        if (oldVal !== null && newVal !== oldVal) {
          this.selectedWarehouse = null;
          this.selectedWarehouseArea = null;
        }
      },
      immediate: true
    }
  }
};
</script>
